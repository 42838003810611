import React, { useState } from 'react'
import FilterLayout from 'components/patterns/FilterLayout'
import Checkboxes from 'components/inputs/Checkboxes'
import AutoInput from 'components/inputs/AutoInput'
import Select from 'components/inputs/Select'
import Radios from 'components/inputs/Radios'
import {
  sortAndFormatForAutoInput,
  sortAndFormatGameWeeks,
  sortAndFormatForSelect,
  combinePlayers,
  sortDataFn,
  checkForTruth,
  filterByKeys,
  assignRadioLabel,
  assignRadioFull,
} from 'utils/filterUtils'
import { Transfer, Private, Auction } from 'styles/SvgIcon'

/**
 * TransferFilter component for filtering transfer data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setEntries - Function to set the filtered entries.
 * @param {Function} props.setOpen - Function to set the open state of the filter.
 * @param {Array} props.defaultData - The default data to be filtered.
 * @param {Function} props.setFilterTags - Function to set the filter tags.
 * @param {Array} props.defaultFilterTags - The default filter tags.
 * @param {boolean} props.gwSelectmenuIsOpen - State to control the game week select menu.
 * @param {Function} props.setGwSelectMenuIsOpen - Function to set the game week select menu state.
 *
 * @returns {JSX.Element} The rendered component.
 */
export default function TransferFilter({
  setEntries,
  setOpen,
  defaultData,
  setFilterTags,
  defaultFilterTags,
  gwSelectmenuIsOpen,
  setGwSelectMenuIsOpen,
  managerSelectmenuIsOpen,
  setManagerSelectMenuIsOpen,
}) {
  const defaultTransfers = {
    transfer: false,
    private: false,
    auction: false,
  }
  /**********************************************************
 FILTER STATES
 ***********************************************************/
  const [sortedData, setSortedData] = useState(defaultData)
  const [player, setPlayer] = useState([])
  const [manager, setManager] = useState([])
  const [gw, setGw] = useState([])
  const [transferType, setTransferType] = useState(defaultTransfers)
  const [activeRadio, setActiveRadio] = useState('')

  /**********************************************************
 FORMAT DATA FOR AUTO INPUT FIELDS
 ***********************************************************/
  const [managerList] = sortAndFormatForSelect(
    filterByKeys([...sortedData], ['manager']),
    'manager'
  )
  const [gwList] = sortAndFormatGameWeeks([...sortedData], 'gw')
  const [combinedPLayersList] = combinePlayers([...sortedData])
  const [playerList] = sortAndFormatForAutoInput(combinedPLayersList, 'player')

  /**********************************************************
 FLATTEN SELECTED VALUES INTO ARRAYS TO CHECK IF FILTERS ARE ACTIVE
 ***********************************************************/
  const selectedPlayers = player.flatMap(obj => obj.name)
  const selectedManagers = manager.flatMap(obj => obj.label)
  const selectedGameWeeks = gw.label ? [gw.label] : []
  const selectedSort = assignRadioLabel(activeRadio)

  const filterIsActiveManager = selectedManagers.length > 0
  const filterIsActivePlayer = selectedPlayers.length > 0
  const filterIsActiveGw = selectedGameWeeks.length > 0
  const filterisActiveTransferType = checkForTruth(transferType)

  /**********************************************************
 FILTER OUT SELECTED ITEMS FROM LISTS
 ***********************************************************/
  const filteredManagerList = managerList.filter(
    manager => !selectedManagers.includes(manager.name)
  )
  const filteredPlayerList = playerList.filter(player => !selectedPlayers.includes(player.name))

  /**********************************************************
 FILTER THE DATA
 ***********************************************************/
  const filteredData = [...sortedData]
    .filter(obj => (filterIsActiveManager ? selectedManagers.includes(obj.manager) : obj))
    .filter(obj =>
      filterIsActivePlayer
        ? selectedPlayers.includes(obj.fullNameIn) || selectedPlayers.includes(obj.fullNameOut)
        : obj
    )
    .filter(obj => (filterIsActiveGw ? selectedGameWeeks.includes(`GW${obj.gw}`) : obj))
    .filter(obj => (filterisActiveTransferType ? (transferType[obj.type] ? obj : null) : obj))

  /**********************************************************
 CURRENT FILTERS THAT HAVE BEEN APPLIED
 ***********************************************************/
  const currentAppliedFilters = [
    {
      managers: selectedManagers,
      players: selectedPlayers,
      gameweeks: selectedGameWeeks,
      sort: selectedSort,
      type: transferType,
    },
  ]

  const auctionApplied =
    currentAppliedFilters[0].type.auction &&
    !currentAppliedFilters[0].type.transfer &&
    !currentAppliedFilters[0].type.private

  /**********************************************************
 APPLY AND RESET HANDLERS
 ***********************************************************/

  const handleApplyFilter = () => {
    setEntries(filteredData)
    setFilterTags(currentAppliedFilters)
    setOpen(false)
  }

  const handleResetFilter = () => {
    setEntries(defaultData)
    setSortedData(defaultData)
    setManager([])
    setPlayer([])
    setGw([])
    setTransferType(defaultTransfers)
    setFilterTags(defaultFilterTags)
    setActiveRadio('')
    setOpen(false)
  }

  return (
    <FilterLayout handleApplyFilter={handleApplyFilter} handleResetFilter={handleResetFilter}>
      <AutoInput
        inputLabel='Players'
        selected={player}
        setSelected={setPlayer}
        data={filteredPlayerList}
        placeholderText='Search for players'
        labelText='Search for players'
        noOptionsText="That player doesn't exist"
      />
      <Select
        inputLabel='Managers'
        placeholder='Select managers'
        state={manager}
        setState={setManager}
        data={filteredManagerList}
        menuIsOpen={managerSelectmenuIsOpen}
        setMenuIsOpen={setManagerSelectMenuIsOpen}
        isMulti
      />
      <Select
        inputLabel='Game weeks'
        placeholder='Select a game week'
        state={gw}
        setState={setGw}
        data={gwList}
        menuIsOpen={gwSelectmenuIsOpen}
        setMenuIsOpen={setGwSelectMenuIsOpen}
      />

      <Checkboxes
        inputLabel='Type'
        setChecked={setTransferType}
        columns='3'
        checkboxes={[
          {
            label: 'Transfer',
            value: transferType.transfer,
            stateKey: 'transfer',
            svg: <Transfer />,
          },
          {
            label: 'Auction',
            value: transferType.auction,
            stateKey: 'auction',
            svg: <Auction />,
          },
          {
            label: 'Private',
            value: transferType.private,
            stateKey: 'private',
            svg: <Private />,
          },
        ]}
      />
      <Radios
        inputLabel={`Sort by ${assignRadioFull(activeRadio)}`}
        activeRadio={activeRadio}
        setActiveRadio={setActiveRadio}
        sortDataFn={sortDataFn}
        sortedData={sortedData}
        setSortedData={setSortedData}
        radios={[
          {
            id: 'plAsc',
            name: 'sort',
            label: 'P&L\nAsc',
            value: 'plAsc',
            disabled: false,
          },
          {
            id: 'plDesc',
            name: 'sort',
            label: 'P&L\nDesc',
            value: 'plDesc',
            disabled: false,
          },
          {
            id: 'auctionAsc',
            name: 'sort',
            label: 'Auction\nAsc',
            value: 'auctionAsc',
            disabled: !auctionApplied,
          },
          {
            id: 'auctionDesc',
            name: 'sort',
            label: 'Auction\nDesc',
            value: 'auctionDesc',
            disabled: !auctionApplied,
          },
        ]}
      />
    </FilterLayout>
  )
}
